<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4"></div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Tạo article mới</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <base-alert type="danger" v-if="errText !== ''">
            <strong>Lỗi!</strong> {{ errText }}
          </base-alert>

          <base-alert type="success" v-if="submitSuccess">
            <strong>Đang xử lý tạo article</strong>
          </base-alert>

          <template v-if="submitSuccess">
            <k-progress
              v-for="(progress, index) in api_progress_list"
              :key="index"
              :percent="progress"
              active
              :color="['#f5af19', '#f12711', '#9254de', '#40a9ff', '#5cdbd3']"
            ></k-progress>
          </template>

          <div v-if="isProgressDone" class="my-2">
            <router-link class="active" :to="{ name: 'ListArticle' }"
              >Click here to see the result!</router-link
            >
          </div>

          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-12">
              <base-input
                v-if="!inputs.is_bulk_article"
                v-model="inputs.keyword"
                :inputClasses="errors.get('keyword') ? 'is-invalid' : ''"
                :errors="errors.get('keyword') ? [errors.get('keyword')] : []"
                maxlength="100"
                label="Keyword"
                placeholder="Enter keyword (the main topic of your article)"
              >
              </base-input>

              <base-input
                v-else
                :errors="errors.get('keyword') ? [errors.get('keyword')] : []"
              >
                <textarea
                  v-model="inputs.keyword"
                  :class="[
                    errors.get('keyword') ? 'is-invalid' : '',
                    'form-control',
                  ]"
                  rows="7"
                  :placeholder="
                    inputs.use_section_heading
                      ? `Keyword1
Keyword2
Keyword3
...`
                      : `Keyword1
Keyword2, SubKwd21, SubKwd22
Keyword3, SubKwd31
...`
                  "
                ></textarea>
              </base-input>
            </div>

            <div class="col-md-12 text-center my-4">
              <a
                href="#"
                @click="inputs.is_bulk_article = !inputs.is_bulk_article"
                >{{
                  !inputs.is_bulk_article
                    ? "Switch to Bulk Article Generator"
                    : "Switch to Regular Article Generator"
                }}</a
              >
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <base-input label="Article Length">
                <el-select
                  v-model="inputs.length"
                  filterable
                  placeholder="Very Short (~50 Words)"
                >
                  <el-option
                    v-for="option in lengthOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
              <div
                v-if="errors.get('length')"
                class="invalid-feedback"
                :style="{ display: errors.get('length') ? 'block' : 'none' }"
              >
                {{ errors.get("length") }}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-form-label form-control-label"
              >Add Titles</label
            >
            <div class="col-md-8 py-2">
              <base-switch
                class="mr-1"
                v-model="inputs.title"
                type="primary"
                :disabled="inputs.length == 'very_long'"
              ></base-switch>
              <div
                v-if="errors.get('title')"
                class="invalid-feedback"
                :style="{ display: errors.get('title') ? 'block' : 'none' }"
              >
                {{ errors.get("title") }}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-form-label form-control-label"
              >Add Section Headings</label
            >
            <div class="col-md-8 py-2">
              <base-switch
                class="mr-1"
                v-model="inputs.use_section_heading"
                type="primary"
                :disabled="
                  inputs.length == 'short' ||
                  inputs.length == 'very_short' ||
                  inputs.length == 'very_long'
                "
              ></base-switch>
              <div
                v-if="errors.get('use_section_heading')"
                class="invalid-feedback"
                :style="{
                  display: errors.get('use_section_heading') ? 'block' : 'none',
                }"
              >
                {{ errors.get("use_section_heading") }}
              </div>
            </div>
          </div>

          <div class="form-group row" v-if="inputs.use_section_heading">
            <label class="col-md-4 col-form-label form-control-label"
              >Auto-generate Section Headings</label
            >
            <div class="col-md-8 py-2">
              <base-switch
                class="mr-1"
                v-model="inputs.auto_generate_section_heading"
                type="primary"
              ></base-switch>
              <div
                v-if="errors.get('auto_generate_section_heading')"
                class="invalid-feedback"
                :style="{
                  display: errors.get('auto_generate_section_heading')
                    ? 'block'
                    : 'none',
                }"
              >
                {{ errors.get("auto_generate_section_heading") }}
              </div>
            </div>
          </div>

          <div class="row" v-if="!inputs.auto_generate_section_heading">
            <label class="col-md-4 col-form-label form-control-label"></label>
            <div class="col-md-8 py-2">
              <div
                v-for="index in sectionHeadingsOptions[inputs.length].max"
                :key="index"
              >
                <div class="row">
                  <label class="col-md-2 col-form-label form-control-label"
                    >Heading {{ index }}
                    {{
                      index <= sectionHeadingsOptions[inputs.length].required
                        ? "*"
                        : ""
                    }}</label
                  >
                  <div class="col-md-6">
                    <base-input
                      v-model="inputs.section_headings[index - 1]"
                    ></base-input>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="row"
            v-if="!inputs.use_section_heading && !inputs.is_bulk_article"
          >
            <div class="col-md-12">
              <base-input label="Sub-keywords">
                <textarea
                  v-model="inputs.sub_keywords"
                  :class="[
                    errors.get('sub_keywords') ? 'is-invalid' : '',
                    'form-control',
                  ]"
                  rows="7"
                  placeholder="Add sub-keywords (one per line, no comma, parentheses, bracket, or too many single characters)"
                ></textarea>
              </base-input>
              <div
                v-if="errors.get('sub_keywords')"
                class="invalid-feedback"
                :style="{
                  display: errors.get('sub_keywords') ? 'block' : 'none',
                }"
              >
                {{ errors.get("sub_keywords") }}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-form-label form-control-label"
              >Add an Image</label
            >
            <div class="col-md-8 py-2">
              <base-switch
                class="mr-1"
                v-model="inputs.image"
                type="primary"
              ></base-switch>
              <div
                v-if="errors.get('image')"
                class="invalid-feedback"
                :style="{ display: errors.get('image') ? 'block' : 'none' }"
              >
                {{ errors.get("image") }}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-form-label form-control-label"
              >Add a Video</label
            >
            <div class="col-md-8 py-2">
              <base-switch
                class="mr-1"
                v-model="inputs.video"
                type="primary"
              ></base-switch>
              <div
                v-if="errors.get('video')"
                class="invalid-feedback"
                :style="{ display: errors.get('video') ? 'block' : 'none' }"
              >
                {{ errors.get("video") }}
              </div>
            </div>
          </div>

          <base-button
            type="primary"
            native-type="submit"
            @click.prevent="onSubmit"
            :loading="isLoading"
            >Submit</base-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import _ from "lodash";
import Errors from "@/utils/error";

export default {
  name: "AddArticle",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      inputs: {
        keyword: "",
        sub_keywords: "",
        length: "very_short",
        title: true,
        use_section_heading: false,
        auto_generate_section_heading: true,
        image: false,
        video: false,
        section_headings: [],
        is_bulk_article: false,
      },
      errors: new Errors(),
      errText: "",
      submitSuccess: false,
      isLoading: false,
      lengthOptions: [
        { label: "Very Short (~50 Words)", value: "very_short" },
        { label: "Short (~250 Words)", value: "short" },
        { label: "Medium (~500 Words)", value: "medium" },
        { label: "Long (~750 Words)", value: "long" },
        { label: "Very Long (~1500 Words)", value: "very_long" },
      ],
      sectionHeadingsOptions: {
        medium: { max: 3, required: 2 },
        long: { max: 5, required: 3 },
        very_long: { max: 8, required: 4 },
      },
      api_progress: 0,
      api_progress_list: {},
      article_id: null,
      isProgressDone: false,
      articleList: [],
    };
  },
  watch: {
    "inputs.length": function (length) {
      if (length == "short" || length == "very_short") {
        this.inputs.use_section_heading = false;
      }

      if (["medium", "long", "very_long"].includes(length)) {
        this.inputs.use_section_heading = true;
        this.inputs.title = true;
      }
    },
    "inputs.title": function (title) {
      if (this.inputs.length == "long" || this.inputs.length == "medium") {
        if (title == false) {
          this.inputs.use_section_heading = false;
        }
      }
    },
    "inputs.use_section_heading": function (use_section_heading) {
      if (this.inputs.length == "long" || this.inputs.length == "medium") {
        if (use_section_heading == true) {
          this.inputs.title = true;
        }
      }
    },
  },
  computed: {
    hasErrors() {
      return !_.isEmpty(this.errors.errors);
    },
  },
  methods: {
    resetForm() {
      this.inputs = {
        keyword: "",
        sub_keywords: "",
        length: "very_short",
        title: true,
        use_section_heading: false,
        auto_generate_section_heading: true,
        image: false,
        video: false,
        section_headings: [],
      };
    },
    onSubmit() {
      let self = this;
      this.submitSuccess = false;
      this.errors = new Errors();
      this.errText = "";
      this.isLoading = true;

      this.$store
        .dispatch("articles/addArticle", self.inputs)
        .then((response) => {
          this.resetForm();
          this.submitSuccess = true;
          this.isLoading = false;
          this.articleList = response.result.articles;
          let articles = response.result.articles;
          articles.forEach((article) => (self.api_progress_list[article] = 0));

          self.getApiProgress(articles[0]);
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 422) {
            self.errors.record(err.response.data);
          }

          if (err.response.status === 503) {
            self.errText = "Tạo article không thành công xin vui lòng thử lại!";
          } else {
            self.errText = "Không thể tạo article lúc này, xin thử lại sau!";
          }

          self.isLoading = false;
        });
    },
    getApiProgress(articleId) {
      let self = this;
      let progressCheck = setInterval(async () => {
        try {
          let response = await this.$store.dispatch(
            "articles/getApiProgress",
            articleId
          );
          let progress = response.result.progress;
          self.api_progress_list[articleId] = Math.round(progress * 100);
          self.$forceUpdate();

          if (progress == 1) {
            clearInterval(progressCheck);
            self.saveArticleContent(articleId);
          }

          // Re-calculate total progress
          let totalProgress = 0;
          for (let key in self.api_progress_list) {
            totalProgress += self.api_progress_list[key];
          }
          console.log(totalProgress);
          console.log(Object.keys(self.api_progress_list).length * 100);
          if (
            totalProgress >=
            Object.keys(self.api_progress_list).length * 100
          ) {
            self.isProgressDone = true;
          }
        } catch (err) {
          console.log(err);
        }
      }, 10000);
    },
    saveArticleContent(articleId) {
      this.articleList.shift();
      this.$store
        .dispatch("articles/saveArticleContent", articleId)
        .then(() => {})
        .catch(() => {});

      if (this.articleList.length > 0) {
        this.getApiProgress(this.articleList[0]);
      }
    },
  },
};
</script>

<style scoped></style>
